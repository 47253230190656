*{margin: 0; padding: 0;}
#root{position: fixed; top:0; left: 0; right: 0; bottom: 0;}
.content{position: fixed; top:0; left: 0; right: 0; bottom: 0;}
.contentWrapper{position: fixed; top:0; left: 0; right: 0; bottom: 0; overflow: hidden;}
main{position: absolute; top:0; left: 0; bottom: 0; right: 0; z-index: 0; overflow-y: scroll;}

.hiddenMenu{z-index:4; background-color:white;}
.scrolYblock{overflow-y: scroll;}

main>.info-animated-left{
    left: 0;
    transition: left 1500ms linear;
}
main .action{overflow-y: scroll;}
main .history{position: relative; }
.logo{display: none;}
@media (min-width: 1024px) {
  .contentWrapper{position: fixed; top:0; left: 250px; right: 0; bottom: 0; overflow: hidden;}
	main>.phoneCall{display:none;}
	main>.content{position:absolute; top:0px; left:0px; right:0px; bottom:0px; width:auto; }
	main{box-sizing:border-box;}
  main .history{max-width: 50%; margin: 0 auto; }
  main .order{width: 50%; margin: 0 auto;}
	.body_nonscroll>.body_item>.info{ height:100%;}
	.body_nonscroll>.orders>.info {padding: 0 25%; box-sizing:border-box;}
	.body_nonscroll>.Order >.info>section{padding: 0 25%; box-sizing:border-box;}
	
	.confirmButton:after{content:'>>'; display:block; position:absolute; color:white; font-size:20px; 
							right:30px; }
	.confirmButton{left: 250px; width: calc(100% - 250px); background-size: 200% 100%; animation:gradient 2s ease infinite; border-top-left-radius:0px;  border-top-right-radius:0px;}
  .background{position: absolute; top:0; left: 250px; width: calc(100% - 250px); bottom: 0;}
  .animatedLogo{top: 50%; left: 50%; transform: translate(-50%, -50%);}
  .selection_filters{left:250px;}
  .selection {position:absolute; left: 250px; top:0; width: calc(100% - 250px); height: 100%;}
  .MapClubs{left: 250px;}
  .confirm_order{left: 250px;}
  .club>.info >.header_tt{max-width: 100%;} 
  .logo{display: block;}
  .content .header_tt{font-size: 30px;}
  .cancelOrderBlock{max-width: 50%; margin: 0 auto;}
}
