/*******************************************Авторизвация пользователя**********************************************/
.registration {position:absolute; top:0; left:0; width:100%; bottom:0;}
--ReactInputVerificationCode-itemWidth
.err_phone_login .number{border: red 1px solid; border-radius: 5px;}
.react-tel-input .form-control{width: 100%!important;}
.hLNLVq{width: 100%!important;}
.err_phone_login .err_msg{height: 40px!important; padding: 10px 0;}
.registration h1{font-weight: 500; font-size: 25px; line-height: 108.9%; margin:0 0 20px 0;}
.registration .input{position:relative; height:48px; 
					border: 1px solid rgba(0, 0, 0, 0.12); box-sizing: border-box; border-radius: 8px;
					margin-bottom:20px;}
.registration .input input{position:absolute; left: 18px; top: 12px; bottom: 12px; width:calc(100% - 36px);
							font-size: 16px; line-height: 24px; color: rgba(0, 0, 0, 0.54);
							border:none;}
form .submit{position:relative; width:100%;}
from .submit-load{position:relative; width:100%;}
from .submit>div{position: relative;  left: 0; padding: 0px; box-sizing: border-box; display: flex; justify-content: center; opacity:0; height:0;}
from .submit>div .grecaptcha-badge{width: 256px; height: 60px; display: block; transition: right 0.3s ease 0s;
											position: relative; right: 4px; box-shadow: grey 0px 0px 5px; border-radius: 2px; overflow: hidden;}
form .submit>input{position:relative; display:block; height:48px; width:100%;
				background: linear-gradient(105.72deg, #AFDB00 0%, #66B700 100%);
				box-shadow: 0px 3px 15px rgba(149, 192, 22, 0.7);
				border:none; border-radius: 42px;
				font-weight: 600; font-size: 14px; line-height: 16px; color:white; opacity:1;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
form .submit span{display: none;}
form .submit-load span{
	position:relative; display:block; height:48px; width:100%; line-height: 48px;
	text-align: center; border-radius: 42px; color:white;
	background:linear-gradient(90deg, rgba(230,233,233,1) 0%, rgba(193,193,193,1) 50%, rgba(230,233,233,1) 100%); background-size: 400% 400%;
							color:white; animation:gradient 3s ease infinite; box-shadow:none;
}
form .submit-load input{display: none;}	
			
.registration .submiting_phone:after{display:flex;}
.registration .submiting_phone>input{opacity:0;}

.registration  .reg_type_list>div{margin-bottom:20px; position:relative; width:100%; 
									padding:15px; box-sizing:border-box; text-align:center;
									box-shadow: 0px 3px 15px rgba(149, 192, 22, 0.7);
									border-radius: 42px;
									font-weight: 600; font-size: 14px; line-height: 16px;
								}
.registration  .reg_type_list>div>svg{display:block; position:absolute; left:20px; top:50%; transform:translate(0,-50%);
									width: 20px; height:20px;}



.registration>section{transition:transform 0.2s ease-in; background-color:white;}

.registration>section:nth-child(1){transform:translateX(0%); z-index:3;}
.registration>section:nth-child(2){transform:translateX(0%); z-index:2;}
.registration>section:nth-child(3){transform:translateX(100%); z-index:1;}

.registration>section{position:absolute; overflow:hidden; overflow-y:scroll; left:0; top:0; width:100%; height:100%; }

	.registration>section>.frame{position:absolute; width:100%; left:0; top:75px;}
	.registration>.sms_confirm>.frame{position:absolute; width:100%; left:0; top:50%; transform:translateY(-50%);}
	.registration>.login>.frame{position:absolute; width:100%; left:0; top:30px; transform:translateY(0%);}
	.registration>.sms_confirm form{position: relative; width: 100%;}
	.registration>.sms_confirm form .smsBlocksFrame{position: relative; width: 100%;}

		.registration>.login .phone_login>.number{position:relative; display:flex; justify-content:space-between;}
			.registration>.login .phone_login>.number>div{border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 5px;
												position:relative;
												height:48px;
												box-sizing:border-box;
												transition:border 0.2s ease-in;
			}
			.registration>.login .phone_login>.number>div>input{display:block; position:absolute;
												top:2px; left:10px; width:calc(100% - 20px); bottom:2px;
												font-size:20px; letter-spacing:5px;}
			.registration>.login .phone_login>.number>div>select{display:block; position:absolute;
												top:2px; left:10px; right:10px; bottom:2px;
												font-size:16px; 
												border: none; background-color: rgba(255,255,255,0);}
			.registration>.login .phone_login>.number>div:nth-child(1){width:30%;}
			.registration>.login .phone_login>.number>div:nth-child(2){width:68%;}
		.registration>.login select{border:none; color: rgba(0, 0, 0, 0.54); background-color: rgba(255,255,255,0);}
		.registration>.login select{outline: none;}
		.registration>.login input{border:none; color: rgba(0, 0, 0, 0.54);}
.registration p{border:none; color: rgba(0, 0, 0, 0.54); margin:10px 0 15px 0;}
.registration  .user_condition{display:flex; align-items:center; margin: 20px }
.registration  .user_condition>input{display:block; position:relative; border:none; color: rgba(0, 0, 0, 0.54); width:30px; height:30px; }
.registration  .user_condition>a{display:block; font-size:12px; width:calc(100% - 30px);}
.registration  .user_condition>span{display:block; font-size:12px; width:calc(100% - 30px);}
.registration  .user_condition>lable{display:block; font-size:12px; width:calc(100% - 30px);}
.registration>.login .submit>input{color:white;}
.registration>.login .submit>input:hover{cursor:pointer;}

.registration .sms_inputs{position:relative; width:100%; height:48px; overflow:hidden;}

.registration .sms_inputs{ border:1px solid rgba(0, 0, 0, 0.12); border-radius: 8px;}
.registration .sms_inputs>input{display:block; position:absolute; top:2px; width:100%; bottom:2px; border:none; 
				padding:0; margin:0; border:0; letter-spacing:10px;
				color:rgba(0, 0, 0, 0.54); font-size:28px; text-align:center;}
.registration .sms_inputs>input:focus{outline: none;}

.registration .sex{position:relative; overflow:hidden; width:100%; margin:10px 0 10px 0; height:46px;
				border: 1px solid rgba(0, 0, 0, 0.08);
				box-sizing: border-box;
				border-radius: 46px;
				}
	.registration .sex>div{position:absolute; left: 0; top:0; width:50%; height:100%; transition:color 0.2s ease-in;
							display:flex; justify-content:center; align-items:center;
							font-weight: 600;
							font-size: 14px;
							line-height: 16px;
							letter-spacing: -0.2px;
							color: rgba(0, 0, 0, 0.54);
						}
	.registration .sex>div:hover{cursor:pointer;}
	.registration .sex>div:nth-child(1){left:0;}
	.registration .sex>div:nth-child(2){left:50%; color:white;}
	.registration .sex>span{
		position:absolute; top:0; left:0; width:50%; height:100%;
		background:linear-gradient(96.96deg, #AFDB00 0%, #66B700 100%);
		transform:translateX(100%);
		transition:transform 0.2s ease-in;
	}

	.registration .female>span{
		transform:translateX(0%);
	}

	.registration .female> #female{color:white;}
	.registration .female>div:nth-child(2){color:inherit;}
			
	.registration .name_txt{
		position:relative; width:100%; height:46px; color:rgba(0, 0, 0, 0.54); border-radius: 46px;
		border:1px solid rgba(0, 0, 0, 0.12);
		margin-top:10px;
	}
	.registration .name_txt input{position:absolute; top:2px; left:15px; right:15px; bottom:2px;
				width: calc(100% - 30px);
				border:none; color: rgba(0, 0, 0, 0.54); 
				margin:0; padding:0; font-size:24px;color:rgba(0, 0, 0, 0.54); 
				}
	.registration input:focus{outline: none;}
	
	.registration .choseCity{
		position:relative; width:100%; height:46px; color:rgba(0, 0, 0, 0.54);
		margin-top:10px;
		border:1px solid rgba(0,0,0,0.12); border-radius: 46px; color: rgba(0, 0, 0, 0.54); 
	}
	.registration .choseCity select{
			position:absolute; top:3px; left:10px; bottom:3px; width:calc(100% - 20px);
			border:none; background-color:white;
			margin:0; padding:0; font-size:24px; 
			color: rgba(0, 0, 0, 0.54); 
			-webkit-appearance: none;
	}
	.registration .choseCity select:focus{outline: none;}
	
	.registration .back{position:absolute; top:20px; left:20px; width:40px; height:40px;}
		.registration .back:hover{cursor:pointer;}
		.registration .back svg{display:block; position:absolute; left:0; top:0; width:100%; height:100%; }
		.registration .err_msg{margin:0 0 10px 0;  height:0px; transition:height 0.2s ease-in; overflow:hidden;}
		.registration .err_msg>span{display:block; padding:10px;
				box-sizing:border-box; border:1px solid rgba(255, 0, 0, 0.42);
				border-radius:10px; color:rgba(255, 0, 0, 0.42); font-size:11px;}
@media (min-width: 1024px) {
	.registration>section{padding:0 25%; box-sizing:border-box;}
	.registration>.login{padding:0 25%; box-sizing:border-box;}
	.registration>section>.frame{position:relative!important;}
	.registration>.login>.frame{position:relative!important; width:100%; left:0; top:50%; transform:translateY(-50%);}
	.registration>section>.frame{position:absolute; width:100%; left:0;  top:50%; transform:translateY(-50%);}
}