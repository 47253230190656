#action{display:none;}
	.action>.name{display:flex; justify-content:space-between; align-items:center;}
	.action>.name>div:nth-child(2){width:80px;}
		.action>.name h1{font-weight: 500; font-size: 26px;}
.action .list{padding-bottom:80px;}
.action_item{border-bottom:var(--border-line-bl);}
.action_item .img{	position:relative;	width:100%;	padding-bottom:38%; height:0; overflow:hidden; border-radius:10px;}
	.action_item .img>img{position:absolute; top:0; left:0; width:100%; }
	
	.action_item .img>.like{display:block; position:absolute; right:10px; top:10px; width:50px; height:50px; border-radius:50%; background-color:var(--like-bg-color); padding:5px; box-sizing:border-box;}
		.action_item .img>.like:hover{cursor:pointer;}
		.action_item .img>.like>svg{display:block; width:40px; height:40px;}
	
	.action_item h2{font-weight: 600; font-size: 20px; line-height: 24px;}
	.action_item p{margin-bottom:5px;}
	.action h2{margin: 20px 0;}

	@media (min-width: 1024px) {
		.action .list{max-width: 50%; margin: 0 auto;}
	}
	