/***********************************КЛУБ********************************/
.orders{position:absolute; top:0; left:0; width:100%; height:100%;}

.orders h1{font-weight: 500; font-size: 26px; line-height: 108.9%; margin-bottom:0!important;}
.orders h2{font-weight: 600; font-size: 20px; line-height: 24px;}
.last_order{border-bottom: var(--border-line-bl);}
.last_order:hover{cursor:pointer;}
.last_order .img{	position:relative;	width:100%;	padding-bottom:38%; height:0; overflow:hidden; -webkit-overflow-scrolling: touch; border-radius:10px;}
	.last_order .img>img{position:absolute; top:0; left:0; width:100%; }
	.last_order .img>.name_and_price{position:absolute;  bottom:0; width:100%; color:white; padding:10px; box-sizing:border-box;}
	.last_order .img>.name_and_price:before{content:'';background-color:var(--img-text-bg-color); position:absolute; top:0; left:0; bottom:0; right:0;}
	.last_order .img>.name_and_price>h1{position:relative; display:flex; justify-content:space-between; margin:0; }
	
	.last_order .order_info{display:flex; justify-content: space-between; font-size:13px; padding:10px 0 20px 0;}
	.last_order .order_info b{font-size:20px;}
		.last_order .order_info>div:nth-child(1){width:75%;  padding-top:10px;}
		.last_order .order_info>div:nth-child(1)>div{display:flex;}
			.last_order .order_info>div:nth-child(1)>div:nth-child(1){padding-bottom:20px;}
		.last_order .order_info>div:nth-child(1)>div>div{display:flex;}
		.last_order .order_info>div:nth-child(2){width:25%; text-align:right;}
	
	.last_order .order_info svg{display:block; width:15px; height:15px; padding-right:10px;}
	
	.orders .history{padding-bottom:120px;}
	.history .order_item{font-size:13px;}
		.history .order_item:hover{cursor:pointer;}
		.history .order_item h3{margin:0; font-size:16px;}
		.history .order_item>div{display:flex; justify-content:space-between;}
		.history .order_item .name{padding-bottom:10px;}
		.history .order_item .info>div:nth-child(1){display:flex; width:20%;}
		.history .order_item .info>div:nth-child(2){width:50%;}
		.history .order_item .info>div:nth-child(3){width:30%; text-align:right;}
	.history svg{display:block; width:15px; height:15px; padding-right:10px;}