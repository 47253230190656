

.header {position:fixed;
		background:white;
		left:0; right:0; top:0; height:80px;
		 box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.24);
}

.header .arrow{
	position:absolute;
	left:20px;
	top:50%;
	transform: translate(-50%, -50%);
}

.header .name{
	position:absolute;
	left:50%;
	top:50%;
	transform: translate(-50%, -50%);
	font-weight: 600;
	font-size: 16px;
}

.header svg{display:block; width:20px; height:20px;}

	.check_order .orderInfo{border-bottom:1px solid rgba(0,0,0,0.1);  }
	.check_order .orderInfo>div{display: flex; align-items:center; margin:10px 0; position:relative; }
	.check_order .orderInfo>div>div:nth-child(1){min-width: 25%;}
	.check_order .orderInfo>div h3{margin:0;}
	.check_order .orderInfo>div>div:nth-child(1){margin-right:10px; color:rgba(0, 0, 0, 0.54);}
	.check_order .date_time{display: flex; justify-content:space-between; border-bottom:1px solid rgba(0,0,0,0.1);  }
	
	.check_order .date_time:nth-child(1){padding-top:10px;}
	.check_order .date_time{display:relative; font-size: 14px; color: rgba(0, 0, 0, 0.54); line-height: 21px;}
		.check_order .date_time>div:nth-child(1){width:30%;}
		.check_order .date_time>div:nth-child(2){width:30%;}
		.check_order .date_time>div:nth-child(3)
		{
			width:40%; text-align:right;
			display: flex; align-items: center; justify-content: flex-end;
		}
		.check_order .timeCostDiscount
		{
			display: flex;
			background-color: var(--background_blue);
			border-radius: 10px;
			padding:1px 5px; color: white;
			font-size: 11px;
			text-align: center; line-height: 13px;
			font-weight: 200;
		}
		.check_order .date_time b{font-weight: 600; color:var(--main-txt-color); }
		
		.check_order .date_time .value{display:flex; }
		.check_order .date_time .value svg{display:block; width:20px; height:20px; padding-right:10px;}
			.check_order .date_time .value svg path{stroke:rgba(0, 0, 0, 0.54);}

.price_section>.amount{border-bottom:1px solid rgba(0,0,0,0.1);}
.price_section>.add_service {border-bottom:1px solid rgba(0,0,0,0.1); }
.price_section>.date_time {border-bottom:1px solid rgba(0,0,0,0.1); }
.price_section h3{color:var(--txt-color-green); margin-bottom:0;}
.price_section .total{display:flex; align-items:center; justify-content:space-between; padding-bottom:10px;}
.price_section .total h3{margin:0!important;}
.price_section .discount>div:nth-child(2){display:flex; align-items:center;}
.price_section .discount>div:nth-child(2)>div:nth-child(1)
	{
		display:block; padding:2px 10px;
		background-color: var(--background_blue);
		color:white; margin-right:5px; 
		font-weight: 200; font-size: 12px;
		border-radius:20px;
	}
.add_service>div{display:flex; justify-content:space-between; padding-bottom:20px; font-size: 14px; line-height: 24px;}
	.add_service>div>div:nth-child(1){width:60%}
	.add_service>div>div:nth-child(2){
		width:40%; text-align:right; font-weight: 300;
		display: flex; align-items: center; justify-content: flex-end;
		font-size: 13px;
	}
	.add_service>div>div:nth-child(2)>div:nth-child(2){padding-left: 5px;}

.payment, .rules{font-size: 13px; line-height: 20px;}
	.payment span{font-weight: 600; font-size: 16px; line-height: 21px;}
	.rules a{color:var(--a-color); font-weight: 600; font-size: 16px; line-height: 21px;}
	.check_order p{margin:0; font-size:12px; color:var(--txt-color-gray);}
.payOrder {display:flex; align-items:center; justify-content:space-between;}
.confirm_order_payed{display:none;}
	.add_to_order h3{margin:0;}
	
	.add_to_order .list>div{display:flex; justify-content:space-between; font-size: 16px; }
		.add_to_order .list>div span{font-weight: bold; font-size: 12px; line-height: 16px; color: #63B900;}
		.add_to_order .list>div>div:nth-child(1){width:80%}
		.add_to_order .list>div>div:nth-child(2){width:20%; text-align:right; font-weight: 600; position:relative;}
			.add_to_order .list .checkcontainer_active{right:0; top:50%; transform:translate(0,-50%);}
			.add_to_order .list .checkcontainer{right:0; top:50%; transform:translate(0,-50%);}
			

.check_order .confirmation_text {display:block; position:fixed; top:calc(50% - 40px); left:50%; transform:translate(-50%,-50%); width:60%; }
	.check_order .confirmation_text h1{margin:0;font-weight: 500; font-size: 26px; line-height: 108.9%; letter-spacing: -0.25px; color: #63B900;}
	.check_order .confirmation_text p{margin-top:20px; font-size: 16px; line-height: 24px; letter-spacing: -0.2px; color: #263238;}
	
	.check_order .contine{display:block; position:fixed; bottom:0; left:0; right:0;
							padding:10px 0 10px 0; text-align:center; color:white; 
							background:linear-gradient(108.36deg, #AFDB00 0%, #66B700 100%); 
							box-shadow: 0px 5px 15px rgba(149, 192, 22, 0.5);
							border-radius: 20px 20px 0px 0px;
						}
	.check_order .contine:hover{cursor:pointer;}

.orderCanceled .payment{display:none}
.orderCanceled .payOrder {display:none}
.orderCanceled .rules{display:none}
.orderCanceled .cancelThisOrder{display:none}
.order .orderIsCanceled{display:none;}
.orderCanceled .orderIsCanceled{display:block;}
@media (min-width: 1024px) {
	.add_service>div{font-size: 16px;}
	.check_order .date_time{font-size: 16px;}
}