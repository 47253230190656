
.club_list_section{position:absolute; top:0; width:100%; height:100vh; overflow:hidden; overflow-y:scroll; -webkit-overflow-scrolling: touch;
				transform:translateX(0); transition:transform 0.2s ease-in; background-color:white;}
	.body>h1{font-weight: 500; font-size: 26px; line-height: 108.9%;}

#contentMap{display:none;}
/**************************************Списки клубов************************************************************/
.clubs{ position:absolute;  z-index:2;}
.clubItem{position: relative;}
.body h1{margin-top: 20px; margin-bottom: 20px;}
.search_clubs .clubs{margin-top:150px;}
.my_clubs_list .clubs{margin-top:0px;}
.clubs span{text-transform:capitalize;}
.clubs .club{margin:4px 0 4px 0; box-shadow:0px 2px 2px rgba(0,0,0,0.1), 0px -1px 2px rgba(0,0,0,0.1);}
.MapClubs .club{box-shadow: none; margin: 0;}
/*.clubs .club{border-bottom:var(--border-line-bl);}*/
.clubs .name_and_price{font-weight: 600; font-size: 18px; line-height: 24px;}
.clubs .name_and_price h1{font-weight: 600; font-size: 20px; line-height: 24px;}
.clubs .img{border-radius:10px;}
.clubs .filter_list>span{text-align:justify;}
	.clubs .filter_list{color:var(--txt-color-gray);}
	.clubs .main{text-align:left; color:var(--main-txt-color);}
	
	.clubs .filter_list>span>i{padding:3px; color:gray}
	
	.adress_a_marks>div>div:nth-child(3){color:var(--txt-color-gray);}
/**************************************Сообщение о плохих результатах поиска************************************************************/
.no_search_results{position:relative; width:100%; height:calc(100vh - 200px); background:white; display:flex;
					justify-content:center; align-items:center; color:var(--txt-color-green); font-size:16px;}
.no_search_results>div{text-align:center; line-height: 1.5;}

/*******************************************КНОПКА ДОБАВЛЕНИЕ В МОИ КЛУБЫ****************************************************/
	.like_my_club {display:flex; position:absolute; right:10px; top:20px; width:70px; height:70px;
								align-items:center; justify-content:center;}
	.like_my_club .like{display:flex; position:relative; align-items:center; width:50px; height:50px; justify-content:center;  border-radius:50%;
		background-color:var(--like-bg-color); padding:5px; box-sizing:border-box;}
		.like_my_club .like:hover{cursor:pointer;}
		.like_my_club .like:active{background-color:var(--background-color-gr)}
			.like_my_club .like:active{fill:white;}
		.like_my_club .like>svg{display:block; width:25px; height:25px;}
		.like_my_club .my_club_checked{background-color:var(--background-color-gr)}
		.like_my_club .my_club_checked>svg{fill:white;}
		.like_my_club .my_club_checked:active{background-color:var(--like-bg-color)}
			.like_my_club .my_club_checked:active>svg{fill:black;}


/**************************************СПИСОК КЛУБОВ НА КАРТЕ************************************************************/
.clubListMap{position: absolute; top:0; left: 0; width: 100%; height: 100%;}
.mapContainer{position: absolute; top:0; left: 0; width: 100%; height: calc(100% - 150px); }
.MapClubs{position:absolute; bottom:0; top:auto; left:0; right:0; background:white; padding-top:5px; 
			    border-top-left-radius: 10px;  border-top-right-radius: 10px; z-index:20; transition:transform 0.2s ease-in;}
.MapClubs>.touchMapClubsList{display:block; position:absolute; top:0; left:0; width:100%; height:40px;z-index:12; 
								overflow: hidden;}
.MapClubs>.touchMapClubsList>div{ display:block; position:absolute; top:8px; left:50%; width:100px; height:6px;
					border-radius:3px;  padding:0;
					transform:translateX(-50%);  background:rgba(0,0,0,0.3);}
.MapClubs>.touchMapClubsList:hover{cursor:row-resize}
.MapClubs>div{position:relative; width:100%; overflow-x:scroll; font-size:12px; /**scroll-behavior: smooth;*/}
.MapClubs>div>div{position:relative; display: flex;  width: max-content; }
.MapClubs>div>div>div{width:300px;}
.MapClubs h2{font-size:13px!important;}
.MapClubs .adress_a_marks{position:relative; height: 20px; display:flex;
	 align-items:center; font-size:11px; padding: 5px 0 5px 0;}

.MapClubs>div>div{font-size:11px; padding-top:0px;}
.MapClubs .timetables{padding:0px 0 5px 0}
.MapClubsHidden{position:fixed; bottom:0; top:auto; left:0; right:0; background:white; padding-top:5px; 
			    border-top-left-radius: 10px;  border-top-right-radius: 10px; z-index:20; transform:translateY(calc(100% - 30px)); transition:transform 0.2s ease-in;}
.MapClubs .club{padding:5px 10px; transition:background 0.2s ease-in;}
.MapClubs .clubActive{background-color:rgba(0,0,0,0.6); color:white!important; border-radius:10px;}
.MapClubs .clubActive .adress>div{color:white!important;}
.MapClubs .clubActive .adress_a_marks svg{fill:white!important;}
.MapClubs .clubActive .btn_w_g {border:var(--border-line-gr)!important; background-color:var(--background-color-gr);}
.MapClubs .clubActive .btn_w_g>span{color:white!important;}
.MapClubs .clubActive .svg>svg>path{fill:white;}

.MapClubs .btn_w_g{padding: 5px;}
@media (min-width: 1024px) {
	.clubList{display:flex; flex-wrap: wrap; justify-content:space-between; padding: 0 10px;}
	.clubList>div{width:calc(50% - 10px); padding-bottom:15px;}
	.clubList .club { border-radius:10px;}
	.MapClubs>div>div>div{width:300px;}
	.MapClubs>div>div>div:hover{cursor:pointer}
	
}