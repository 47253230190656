.filtrt_header{
	position:absolute; top:0; left:0; width:100%; background-color:white;
	display:flex; justify-content:space-between; box-shadow:var(--box-shadow-down);
}
.filtrt_header svg{display:block; width:30px; height:30px;}
.filtrt_header .name{display:flex; align-items:center;}
.filter_type3{display:flex; flex-wrap:wrap;}
.filter_type3>div{margin:0 0 5px 5px;}
.selection{display:none; position:absolute; top:0; left:0; bottom:0; width:100%;}
.selection .overlay{position:absolute; top:0; bottom:0; left:0; right:0; background-color:rgba(0,0,0,1); opacity:0; transition:opacity 0.2s ease-in;}
.filter_selection{position:absolute; top:20%; bottom:0; left:0; width:100%;
					border-top-left-radius:20px; border-top-right-radius:20px; 	z-index:3;
					background:white; transform: translateY(100%); transition: transform 0.2s ease-in;}
.selection .filter_area{position:absolute; top:40px; bottom:50px; left:0; width:100%; background-color:white;
						display: flex;   align-items: center;  justify-content: center;}
.selection .filter_area>div{position:relative; width: 95vmin; height: 110vmin; margin: auto; flex: 0 1 auto; background-color:white;}
.selection .filter_area>div>.filter_content{position:absolute; top:0; left:0; right:0; bottom:0;}
.selection .filter_area>div>.filter_content .innerMsg{padding-top:20px; text-align:center; font-size:18px; }

.selectionActive{display:block; z-index:4;}		
.selectionActive .overlay{opacity:0.6}
.selectionActive .filter_selection{transform: translateY(0%);}

.calendar{position:relative; padding-bottom:120%; height:0;}
	.calendar>div{position:absolute; top:0; left:0; right:0; bottom:0;}

.selection .close{position:absolute; top:20px; left:20px;}
.selection .close svg{display:block; width:20px; height:20px;}

.vanilla-calendar-header{padding:10px 50px !important;}
.vanilla-calendar-week{background:white !important; }
.vanilla-calendar-week span{color:var(--txt-color-gray)!important;}
.vanilla-calendar{border:none!important; box-shadow:none!important;}

.vanilla-calendar-date--selected{background:var(--linear-gradient-gr);}
.vanilla-calendar-date{position:relative!important; padding:0!important; padding-bottom: 14.28%!important; height:0!important;}
.vanilla-calendar-date span{position:absolute; top:0; left:0; right:0; bottom:0; text-align:center; line-height:50px;}
.vanilla-calendar-date {border-radius:50%!important;}

.confirm{position:absolute; height:50px; bottom:0; left:0; right:0; 
		display:flex; justify-content:center; align-items:center;
		background:var(--linear-gradient-gr); border-top-left-radius:20px; border-top-right-radius:20px; color:white;}
	.confirm:hover{cursor:pointer;}


.filters_confirm{position:absolute; height:50px; bottom:0; left:0; right:0; 
		display:flex; justify-content:center; align-items:center;
		background:var(--linear-gradient-gr); border-top-left-radius:20px; border-top-right-radius:20px; color:white;}
	.confirm:hover{cursor:pointer;}
	
.filter_selection .time {display:flex; justify-content:space-between;padding:50px; margin-top:30px; margin-bottom:30px;}

/**************************************БЛОК ФИЛЬТРОВ ПО ГОРОДУ И ПАРАМЕТРАМ*************************************************/
.selection_filters{position:absolute; top:0; left:0; width:100%; height:100%; overflow:hidden; transition:opacity 0.2s ease-in; z-index:4}

.filters{position:absolute; top:0; left:0; width:100%; bottom:0; -webkit-overflow-scrolling: touch; background-color:white;}
#close_filters:hover{cursor:pointer;}
.filters .filter_list{position:absolute; top:0; left:0; right:0; bottom:0; padding-top:70px; padding-bottom:60px; overflow-y:scroll;}
.filters .confirm{position:fixed; bottom:0; left:0; right:0;}

.timeSelection{display:flex; justify-content:center;
				position:absolute; top:50%; left:50%; width:100%; 
				transform:translate(-50%,-50%);}
.timeSelection>div{width:50%; display:flex; align-items:center; justify-content:center;}
.timeSelection>div>lable{color:var(--txt-color-gray); font-weight:bold; }
.timeSelection>div>select{margin-left:20px; font-size:18px; padding:4px 8px; border:1px solid rgba(0,0,0, 0.3);
							border-radius:30px;  outline:none;  }

@media screen and (min-aspect-ratio: 3/5) {
  .selection .filter_area>div{ width: 80vmin; height: 90vmin;}
}

@media screen and (min-aspect-ratio: 3/4) {
  .selection .filter_area>div{ width: 70vmin; height: 70vmin;}
}

@media screen and (min-aspect-ratio: 1/1) {
  .selection .filter_area>div{ width: 50vmin; height: 70vmin;}
}

@media (min-width: 1024px) {
.filters .filter_list .filter{ margin:0 auto; max-width:50%}	
}


