.filter_and_search{position:relative; top: 0; width:100%; z-index:4; background:rgba(255,255,255,0.3)}
.filter_and_search>.searchAndFilters{position:relative;  overflow:hidden;}
.filter_and_search{box-shadow:var(--box-shadow-down); color:var(--txt-color-gray);}
.filter_and_search_map{box-shadow:none;}
.filter_and_search svg{fill:var(--icon-color);}
.search{display:flex; justify-content:space-between; margin-bottom:5px; }
	 .mapIcon{position:absolute; right:10px; top:20px; width:48px; height:48px;
						border:var(--border-line-bl); border-radius:10px;
						background-color:white; z-index:5;}
	 .mapIconActive{position:absolute; right:10px; top:20px; width:48px; height:48px;
		border:var(--border-line-bl); border-radius:50px;
		background-color:white; z-index:5;}
	 .mapIcon svg{display:block; position:absolute; top:9px; left:9px; width:30px; height:30px;}
	 .mapIconActive svg{display:block; position:absolute; top:9px; left:9px; width:30px; height:30px;}
	 .mapIcon .active{display: none;}
	 .mapIconActive  .nonActive{display: none;}
	 .mapIconActive:hover{cursor: pointer;}
	 .mapIcon:hover{cursor:pointer;}
	 .mapIcon:active{border:var(--border-line-w); background:var(--linear-gradient-gr);}
	 .mapIcon:active svg{fill:white;}
#contentMap	.mapIcon{border-radius:50%!important; background-color:rgba(255,255,255,0.7)!important;}
	#contentMap .mapIcon svg{display:block; top:50%; left:50%; transform:translate(-50%,-50%); width:20px; height:20px;}

.filter_and_search .input{position:relative; height:48px; padding:5px; width:calc(100% - 80px); box-sizing:border-box; border-radius:10px; border:var(--border-line-bl);}
	.filter_and_search .input input{position: absolute; left: 24.45px; width: 83%; top: 23.51%; bottom: 26.49%; border:none;}

.filter_and_search_map .input{background-color:white;}

.filter_and_search_map .btn_w{background-color:white;}
.filter_and_search_map .map{border: var(--border-line-w); background: var(--linear-gradient-gr); }


.filter_and_search .filter{position:relative; overflow-x:scroll;}
/*.filter_and_search .filter::-webkit-scrollbar { width: 0; }*/
.filter_and_search .filter>div{position:relative; display:flex; width:max-content; padding:3px 0 3px 0;}
.filter_and_search .filter>div>div{margin-right:10px; font-size:12px;}
.filter_and_search .filter>div>div:nth-child(5){display:none!important;}
.filter_and_search .filter>div>div>i{display:block; margin-right:10px;}
.filter_and_search .filter>div>div>i>svg{width:20px; height:20px;}

.filter_and_search .btn_w {background-color:white;}
.filter_and_search .input{background-color:white;}
.filter_and_search input{outline:none;}
.filter_and_search .filterMapChange{display:none;}
.mapFilter{position:fixed; top:0; left:0; bottom:auto; width:100%; transition:transform 0.5s ease-in;}
.mapFilter .filterMapChange{display:block; position:absolute; bottom:8px; left:50%; width:100px; height:6px;
					border-radius:3px;
					transform:translateX(-50%);  background:rgba(0,0,0,0.3);}
.mapFilter .filterMapChange:hover{cursor:row-resize}
.mapFilterHidden{position:fixed; top:0; left:0; width:100%; transform:translateY(calc(20px - 100%)); transition:transform 0.2s ease-in;}
.mapFilterHidden .filterMapChange{ display:block; position:absolute; bottom:8px; left:50%; width:100px; height:6px;
					border-radius:3px;
					transform:translateX(-50%);  background:rgba(0,0,0,0.3); cursor:row-resize}

.filter .btn_g {box-shadow:var(--box-shadow-grn-small);}

@media (min-width: 1024px) {
	.filter_and_search{box-shadow:none;}
	.filter_and_search .filter{position:relative; overflow-x:auto;}
	.filter_and_search { z-index:4; }
}