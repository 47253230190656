


/***********************************Структура********************************/
.body>.club{transform:translateX(0%);}
.body>.timeOrder{transform:translateX(100%);}
.body>.Services{transform:translateX(100%);}
.body>.confirmOrder{transform:translateX(100%);}

.club>.info{position: relative;}
.club .img{	position:relative;	width:100%;	padding-bottom:38%; height:0; overflow:hidden;}
	.club .img>img{position:absolute; top:0; left:0; width:100%; }
	.club .img>.name_and_price{position:absolute;  bottom:0; width:100%; color:white;}
	.club .img>.name_and_price:before{content:'';background-color:var(--img-text-bg-color); position:absolute; top:0; left:0; bottom:0; right:0;}
	.club .img>.name_and_price>.clubInfo{
			position:relative; display:flex; justify-content:space-between; align-items: flex-end;
			margin:0; padding:10px; box-sizing:border-box;}
		.club .img>.name_and_price>.discount{position:relative; width:100%; height:13px;}
			.club .img>.name_and_price>.discount>div{
				font-size: 10px; color:white;  background-color:var(--background_blue); 
				position:absolute; right:10px; bottom:0; height:20px;
				padding:0px 12px; border-top-left-radius:8px; border-top-right-radius:8px;
				display:flex; justify-content:center; align-items:center;}
		.club .img>.name_and_price>div h2{margin:0; font-size:16px;}
		.club .img>.name_and_price>div .price{margin:0; font-size:14px; min-width:20%; text-align:right;}
	.club>.info>.name_and_price {position:relative; display:flex; justify-content:space-between; align-items:center;
								padding-top:10px;
								margin-bottom:0; }
	.club>.info>.name_and_price .discount{position:relative; width:100%; height:20px;}
		.club>.info>.name_and_price .discount>div{
				font-size: 10px; color:white;  background-color:var(--background_blue); 
				position:absolute; left:0px; bottom:0; height:20px;
				padding:0px 12px; border-radius:12px;
				display:flex; justify-content:center; align-items:center;}
	.club>.info>.name_and_price>div:nth-child(1){width:80%;}
		.club>.info>.name_and_price h1{font-size:20px; margin:0; text-align: left}
		.club>.info>.name_and_price .price{font-size: 13px; line-height: 20px; color: rgba(0, 0, 0, 0.54); text-align:center;}
		.club>.info>.name_and_price  b{font-size: 20px; line-height: 32px; color: #263238;}
/*******************************************КНОПКА ДОБАВЛЕНИЕ В МОИ КЛУБЫ****************************************************/
	.club .img>.like{display:flex; position:absolute; align-items:center; justify-content:center; right:10px; top:10px; width:50px; height:50px; border-radius:50%;
		background-color:var(--like-bg-color); padding:5px; box-sizing:border-box;}
		.club .img>.like:hover{cursor:pointer;}
		.club .img>.like:active{background-color:var(--background-color-gr)}
			.club .img>.like:active{fill:white;}
		.club .img>.like>svg{display:block; width:25px; height:25px;}
		.club .img>.my_club_checked{background-color:var(--background-color-gr)}
		.club .img>.my_club_checked>svg{fill:white;}
		.club .img>.my_club_checked:active{background-color:var(--like-bg-color)}
			.club .img>.my_club_checked:active>svg{fill:black;}
	
	.club .img>.back{display:block; position:absolute; left:10px; top:10px; width:50px; height:50px; border-radius:50%; background-color:var(--like-bg-color); padding:5px; box-sizing:border-box;}
		.club .img>.back:hover{cursor:pointer;}
		.club .img>.back>svg{display:block; width:40px; height:40px;}
	
	.club .img>.close{display:flex; align-items:center; justify-content:center; position:absolute; left:10px; top:10px; width:50px; height:50px; border-radius:50%; background-color:var(--like-bg-color); padding:10px; box-sizing:border-box;}
		.club .img>.close:hover{cursor:pointer;}
		.club .img>.close>svg{display:block; width:20px; height:20px;}
	
		.club .adress_a_marks>.marks{display:none;}
		.club .adress_a_marks>div{display:flex; padding:10px 0 10px 0;}
		.MapClubs .adress_a_marks>div{display:flex; padding:5px 0 5px 0; align-items: center;}
			.club .adress_a_marks>div>div{margin-right:10px;}
			.club .adress_a_marks svg{display:block; width:20px; height:20px; }
		
		.club .adress_a_marks>a{display:flex; padding:5px 0 5px 0; text-decoration: none; color:var(--main-txt-color);}
		.club .adress_a_marks a span{color: black!important;}
		.club .adress_a_marks a b{color: black!important;}
		.club .adress_a_marks>a:hover{text-decoration: underline;}
			.club .adress_a_marks>a>div{margin-right:10px;}
			.club .adress_a_marks svg{display:block; width:20px; height:20px; }
		
		.club .filter_list span{color: black;}
		.club .description{margin:0;}
		
		.filter .title{padding-bottom:20px;}
		.filter_type1{display:flex; justify-content:space-between;}
		.filter_type2{display:flex; justify-content:space-between; flex-wrap:wrap;}
		
		.filter .filter_icon{ width:40%; height:0; padding-bottom:20%; position:relative;
								border:var(--border-line-bl); border-radius:20px;
								color:var(--txt-color-gray);
								font-weight:600;}
		
		.filter .filter_icon  svg{display:block; position:absolute; top:0; left:0; width:100%; height:100%; fill:white;}
			.filter .filter_icon  svg>path{stroke:var(--icon-color);}
			.filter .filter_icon  svg>circle{stroke:var(--icon-color);}
			.filter .filter_icon .text{position:absolute; bottom:10%; left:10%; width:80%; text-align:center; }
			
		.filter_type1 .filter_icon{width:48%; height:0; padding-bottom:25%; }
			.filter_type1 .checked{width:calc(48% + 2px); padding-bottom:calc(25% + 2px);}
			.filter_type1 .filter_icon:active{width:calc(48% + 2px); padding-bottom:calc(25% + 2px); color:white;}
			.filter_type1 .filter_icon:active svg{fill:white;}
			.filter_type1 .filter_icon:active svg>path{stroke:white; }
			.filter_type1 .filter_icon:active svg>circle{stroke:white; }
			
		.filter_type2 .filter_icon{width:32%; height:0; padding-bottom:32%; margin-bottom:1%; border-radius:10px;}
			.filter_type2 .checked{width:calc(32% + 2px); padding-bottom:calc(32% + 2px);}
			.filter_type2 .filter_icon:active{width:calc(32% + 2px); padding-bottom:calc(32% + 2px); color:white;}
			.filter_type2 .filter_icon:active svg{fill:white;}
			.filter_type2 .filter_icon:active svg>path{stroke:white; }
			.filter_type2 .filter_icon:active svg>circle{stroke:white; }
		
		
		.filter  .filter_icon:hover{cursor:pointer;}
		.filter  .filter_icon .icon{display:block; position:absolute; left:50%; top:10%; width:32px; height:32px; transform: translateX(-50%);}
			.filter_type1 .icon{width:50px; height:50px;}
			.filter_type2 .icon{ top:20%; width:40px; height:40px;}
			
			.filter_icon:active{background:var(--linear-gradient-gr); box-shadow:var(--box-shadow-grn); color:white; border:none;}
		.filter .btn_w i{font-size:11px;}
		.filter .checked i{color:white!important}
		.filter .checked{background:var(--linear-gradient-gr); box-shadow:var(--box-shadow-grn); color:white; border:none;}
			.filter .checked svg{fill:white;}
			.filter .checked svg>path{stroke:white;}
			.filter .checked svg>circle{stroke:white;}
			
			
		.club_item{display:flex; justify-content:space-between;}
/*******************************************стационарные иконки клубов****************************************************/
	
	.filter_value{display:flex;}
	.filter_value>div{margin-right:100px;}
	.filter_value .title{margin-bottom:10px; color:var(--txt-color-gray); font-weight:400;}
	
		.filter_value>div:nth-child(3){margin-top:10px;}
		.filter_value .value>.filter_icon{display:none; position:relative; align-items:center; display:flex;}
		.filter_value .value .icon{position:relative;}
		.filter_value .value .text{position:relative; color:var(--main-txt-color); }
		.filter_value .filter_icon{margin-bottom:5px;}
		.filter_value .filter_icon  svg{display:block; margin-right:5px; width:15px; height:15px;}
			.filter_value .filter_icon  svg>path{stroke:var(--txt-color-gray);}
			.filter_value .filter_icon  svg>circle{stroke:var(--txt-color-gray);}
/*******************************************стационарные иконки клубов****************************************************/
	.promo{padding-bottom:10px; display:none; }
	.promo>div{margin-bottom:10px; }
	.promo>.input{position:relative; width:100%; height:50px;}
	.promo>.input>input{position:absolute; margin:0; padding:5px 10px; top:0; left:0; bottom:0; width:100%; box-sizing:border-box;}
	.promo>.input>input{border-radius:8px; border: solid 1px rgba(0,0,0,0.1);}
	
	.promo>.input>input:focus{outline: none;}

/***************************************СООБЩЕНИЯ*****************************************************/
.freeTimeMsg{display:none;}
.freeTimeMsg>span{display:block;  border-radius:10px; background-color:rgba(255,0,0,0.5); color:white;}
				
/**********************************************Отзывы*****************************************************************************/
	.comments{display:none;}
		.comments>.title{display:flex; justify-content:space-between; padding-bottom:20px;}
		.comments .title h3{margin:0;}
		.comments .title{padding:20px 0 20px 0;}
			.comments .title>div{display:flex; padding:5px 0 5px 0;}
				.comments .title>div>div{margin-right:10px;}
				.comments .title svg{display:block; width:20px; height:20px; }
				
		.person_info {display:flex;}	
		.person_info .foto{width:50px; height:50px; overflow:hidden; position:relative; border-radius:50%; margin-right:10px;}
			.person_info .foto img{position:absolute; width:100%;}
			
			.comments .text{padding:20px 0 20px 0;}
			
/******************************************Правила*****************************************************************************/
.club .info>div:last-child{padding-bottom:80px!important;}
	
/****************************************Блок с кнопкой продолжиь************************************************************/
.confirm_order{position:fixed; bottom:0; left: 0px; right: 0px; z-index:2; height:60px;
			display:flex; justify-content:space-between; align-items:center; padding:0 10px;
			background-color:var(--fix-bl-bg-color);
			box-shadow:var(--box-shadow-up);
}
.confirm_order .orderSum{display:flex; align-items:center; transition: opacity 0.2s ease-in;}
.confirm_order .orderSum>div:nth-child(1){font-size:12px;}
.confirm_order .orderSum>div:nth-child(2){font-size:11px; margin-left:10px;}
.confirm_order .orderSum>div:nth-child(2) span{color:var(--txt-color-green); font-size:16px;}
.confirm_order .orderSum>div:nth-child(2)>div:nth-child(1){display:none;}
.confirm_order .orderSum>div:nth-child(2)>div:nth-child(3){font-size: 8px; display:none;}

.confirm_order .discount>div:nth-child(2) span{color:var(--txt-color-green);}
.confirm_order .discount>div:nth-child(2)>div:nth-child(1){display:block;}
.confirm_order .discount>div:nth-child(2)>div:nth-child(3){display:block;}

.confirm_order .no_sum{max-width: 59%;}
.confirm_order .cost{max-width: 39%;}
/*******************************************ШКАЛА СВОБОДНОГО ВРЕМЕНИ****************************************************/

			.timetables{padding:20px 0 20px 0;}
			.timetables .line{ position:relative; height:5px; border-radius:5px; background-color:var(--background-scale-frame);}
			
			.timetables .line>.timtable{position:absolute; z-index:1; top:0; height:5px; border-radius:5px; background-color:var(--background-timetable-frame); }
			.timetables .line>.reserved{position:absolute; z-index:2;top:0; height:5px; border-radius:5px; background-color:var(--background-scale-frame); }
			.timetables .line>.scale{position:absolute; top:8px; left:0; width:100%; font-size:10px; }
			.timetables .line>.scale>div{position:absolute; top:0; text-align:center; }
				.timetables .scale>div:before{content:''; display:block; position:absolute; top:-2px; height:3px; width:1px; left:50%; background-color:black;}


		.timtable{position:absolute; z-index:1; top:0; height:5px; border-radius:5px; background-color:var(--background-timetable-frame); }
			.timtable.line>.reserved{position:absolute; z-index:2;top:0; height:5px; border-radius:5px; background-color:var(--background-scale-frame); }
			 .timtable .line>.scale{position:absolute; top:8px; left:0; width:100%; font-size:10px; }
			.timtable .line>.scale>div{position:absolute; top:0; text-align:center; }
				 .timtable .scale>div:before{content:''; display:block; position:absolute; top:-2px; height:3px; width:1px; left:50%; background-color:black;}
/*********************************************ПОДТВЕРЖДЕНИЕ ЗАКАЗА**************************************************/
.content .check_order_confirmation{position:absolute; top:0; left:0; width:100%; height:100%;
					overflow:hidden; overflow-y:scroll; -webkit-overflow-scrolling: touch;
					transition:transform 0.2s ease-in;}
.content .check_order_confirmation .confirmation_text{display: block;
    position: fixed;
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60%; 
	text-align: center;}
.content .check_order_confirmation  .confirmation_text h1 {
    margin: 0;
    font-weight: 500;
    font-size: 26px;
    line-height: 108.9%;
    letter-spacing: -0.25px;
    color: #63B900;
}
.content .check_order_confirmation  .confirmation_text p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #263238;
}
.content .check_order_confirmation .contine {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 0 10px 0;
    text-align: center;
    color: white;
    background: linear-gradient(108.36deg, #AFDB00 0%, #66B700 100%);
    box-shadow: 0px 5px 15px rgba(149, 192, 22, 0.5);
    border-radius: 20px 20px 0px 0px;
}
.MapClubs .adress_a_marks svg{width: 10px; height: 10px;}

@media (min-width: 1024px) {
	.club > .info >div{margin:0 auto; max-width:50%;}
	.club > .info >.img{max-width:100%;}
	
	.filter  .filter_icon .icon{display:block; position:absolute; left:50%; top:10%; width:50%; height:50%; transform: translateX(-50%);}
	
	.timeOrder  .time_selection .filter {margin:0 auto; max-width:50%;}
	.Services  .serviceList{margin:0 auto; max-width:50%;}
	.confirmOrder .order{margin:0 auto; max-width:50%;}
}