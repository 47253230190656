:root{
	--background-scale-frame:rgba(200,200,200,1);
	--background-timetable-frame:rgba(100,200,10,1);
	--body-background-color:rgba(200,200,200,1);
	--background-color-gr:#589D00;
	--background-color-gr-tr:rgba(100,200,10,0.5);
	--background-main:rgba(255,255,255,1);
	--background_blue:#3889ea;
	--img-text-bg-color:rgba(0,0,0,0.3);
	--like-bg-color:rgba(255,255,255,0.6);
	--filter-bg-color:rgba(0,255,0,0.6);
	--linear-gradient-gr:linear-gradient(98.74deg, #B8E600 -12.29%, #5DA600 118.43%);
	--border-line-bl:1px solid rgba(0,0,0,0.1);
	--border-line-wt:1px solid rgba(255,255,255,1);
	--border-line-w:1px solid white;
	--border-line-grn:1px solid transparent;
	--icon-color:rgba(0,0,0,0.54);
	--main-txt-color:rgba(0,0,0,1);
	--txt-color-gray:rgba(0, 0, 0, 0.54);
	--txt-color-green:#589D00;
	--color_txt:#589D00;
	--color_txt_blue:#3889ea;
	--a-color: #388AEA;
	--add-ser-hover:rgba(0,0,0,0.6);
	--fix-bl-bg-color:white;
	--box-shadow-up: 0px -4px 5px 0px rgba(0,0,0,0.45);
	--box-shadow-down:0px 0px 48px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.24);
	--box-shadow-grn: 0px 3px 15px rgba(149, 192, 22, 0.7);
	--box-shadow-grn-small: 0px 1px 3px rgba(149, 192, 22, 0.4);
	--ReactInputVerificationCode-itemWidth: 13%!important
}
html{height:100%;}
body{ position:relative; margin:0; width:100%; height:100vh; font-family: 'Montserrat', sans-serif; color:var(--main-txt-color);}
main{position:fixed; top:0; left:0; right:0; bottom:0; overflow:hidden; }
main>.content{position:fixed; top:0; left:0; width:100%; bottom:0;}
main h1{margin-top: 20px; margin-bottom: 20px; text-align: center; font-size: 30px;}



main>.background{position:absolute; top:0; left:0; width:100%; height:100%; }
		.animatedLogo{position:absolute; top:calc(50% - 42px); left:50%; transform:translate(-50%,-50%); width:80px; height:80px;}
			.animatedLogo>svg{display:block; position:absolute; top:0; left:0; width:100%; height:100%; animation: rotation 2s infinite;}
	@keyframes rotation { from {-webkit-transform: rotate(0deg);}to {-webkit-transform: rotate(359deg);}}
/****************************************ОБЩИЙ ЭЛЕМЕНТЫ*************************************************************/
i{font-style:normal; color:rgba(0,0,0,0.54)}
b{font-weight: 600;}
/***********************************СТРУКТУРА СТРАНИЦ********************************/
.content>.body{position:absolute; top:0; left:0; right: 0; bottom:0; overflow:hidden;
					transform:translateX(0); transition:transform 0.2s ease-in; 
					background-color:var(--background-main);}
.content>.body_nonscroll{overflow:hidden; z-index: 3; }

.content>.body>.body_item{position:relative;}
.content>.body_nonscroll>.body_item{position:fixed; top:0; left:0; width:100%; bottom:0; overflow:hidden; transition:transform 0.2s ease-in;}

.content>.body_nonscroll>.body_item>.info{display:block; position:fixed; top:0; left:0; width:100%; height:calc(100% - 60px); overflow-y:scroll;}

.content>.body>.body_item>.info>div:last-child{padding-bottom:80px;}

.content .header_tt{
	position: relative; z-index: 2;
	display:flex; justify-content:space-between;
	box-shadow:var(--box-shadow-down);
	font-weight: 500; font-size: 21px;
}
.content .header_tt svg{display:block; width:30px; height:30px;}


/**************************************************ОБЩИЕ ЭЛЕМЕНТЫ********************************************************************************/
.frame{padding-left:10px; padding-right:10px; box-sizing:border-box;}
.frame_b{padding-bottom:20px;}
.frame_bt{padding-top:20px; padding-bottom:20px;}

.frame_bt_10{padding-top:10px; padding-bottom:10px;}

.frame_inner_bottom{padding:0px 10px 20px 10px;}

.border{border-bottom:var(--border-line-bl); border-top:var(--border-line-bl);}
.border_bottom{border-bottom:var(--border-line-bl);}

/**********************************КНОПКИ******************************/
.btn_g{display:flex; align-items:center; padding:10px 13px; border-radius:50px; background:var(--linear-gradient-gr); box-shadow:var(--box-shadow-grn); color:white;}
	.btn_g:hover{cursor:pointer;}
	
.btn_w{display:flex; align-items:center; padding:10px 13px; border-radius:50px; border:var(--border-line-bl)}
	.btn_w svg{display:block; width:20px; height:20px;}
	.btn_w:hover{cursor:pointer;}

.btn_w:active{background:var(--linear-gradient-gr); padding:10px 13px; color:white; box-shadow:var(--box-shadow-grn);}
	.btn_w:active svg{fill:white;}


.btn_w_cheked{background:var(--linear-gradient-gr);  padding:10px 13px; border:none; color:white;  box-shadow:var(----box-shadow-grn)}

.color_txt{color:var(--color_txt);}
.gray_txt{color:var(--txt-color-gray);}

.btn_w_g{display:flex; align-items:center; justify-content:center; padding:10px 13px; border-radius:50px; border:var(--border-line-bl)}
.btn_w_g a{text-decoration: none; color:inherit;}	
.btn_w_g:hover{cursor:pointer;}
	.btn_w_g>span{display:block; color:var(--txt-color-green);}
.btn_w_g:active{background:var(--linear-gradient-gr); padding:10px 13px; color:white; border:none; box-shadow:var(----box-shadow-grn);}
	.btn_w_g:active>span{color:white;}

.btn_red{padding:10px 13px; border-radius:50px; background-color: rgba(255,100,100,0.5); color: white;}
.btn_red:hover{cursor: pointer;}

.btnIOS:hover{cursor:pointer;}
.btnIOS>div{position:relative; width:51px; height:31px;
			background:linear-gradient(92.43deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.15) 100%);
			box-shadow: none; transition:background 0.2s ease-in;
			backdrop-filter: blur(16px);
			border-radius: 18px; transform: matrix(-1, 0, 0, 1, 0, 0);}
.btnIOS>div:after{display:block; content:''; position: absolute; width: 25px; height: 25px; right: 3px; top: 3px; background: #FFFFFF;
						border-radius: 25px; transition:right 0.2s ease-in;}
.btnIOS>.checked{background:linear-gradient(92.43deg, rgba(175, 219, 0, 0.85) 0%, rgba(102, 183, 0, 0.85) 100%);
				box-shadow: 0px -3px 15px rgba(149, 192, 22, 0.5);}
.btnIOS>.checked:after{right: 22px; }
/**********************************КНОПКИ ******************************/
.confirmButton{
				display: block;
				position:fixed;  z-index:1; bottom:0; width:100%; 
				padding: 15px 0 15px 0;
				box-sizing:border-box; 
				display:flex; align-items:center; justify-content:center;
				background:var(--linear-gradient-gr);
				box-shadow: 0px 5px 15px rgba(149, 192, 22, 0.5); 
				border-top-left-radius:20px;  border-top-right-radius:20px;
				text-decoration: none; color: inherit;
				left: 0;
}

.confirmButton:hover{cursor:pointer;}
.confirmButton:active{ background-size: 200% 100%; animation:gradient 2s ease infinite;}
.confirmButton span{display:block; color:white; font-weight:600;}
@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		50% {
			background-position: 100% 0%;
		}
		100% {
			background-position: 0% 0%;
		}
	}
	
/******************************Кнопка Позвонить**************************************************************/
.content>.phoneCall{position:fixed; bottom:80px; right:20px; width:50px; height:50px; 
			background:var(--linear-gradient-gr); box-shadow:var(--box-shadow-grn);
			border-radius:50%;}
.content>.phoneCall>a{display:block; position:absolute; top:0; left:0; width:100%; height:100%; padding:12px; box-sizing:border-box;}
.content>.phoneCall>a>svg{display:block; width:26px; height:26px; fill:white;}

@media (min-width: 1024px) {
	.content>.body_nonscroll{overflow:hidden; z-index: 3; left: 250px;}
}

