.paymentSection{position:absolute; top:0; left:0; width:100%; height:100%;}
.paymentSection>.checkout{position:absolute; top:0; left:0; width:100%; height:100%;}
.paymentSection>.checkout #checkout_wrapper{position:absolute; top:0; left:0; width:100%; height:100%;}



.paymentList>div{display:flex; justify-content:space-between; align-items:center;}
	.paymentList>div>div:nth-child(1){width:60%; }
	.paymentList>div>div:nth-child(1)>div{display:flex; align-items:center; }
	
	.paymentList>div>div:nth-child(1)>div:nth-child(1){font-weight: 800; font-size: 18px;line-height: 24px;}
	.paymentList>div>div:nth-child(1)>div:nth-child(2){font-size: 13px; line-height: 20px; }
	
	.paymentList>div>div:nth-child(1)>div:nth-child(2)>div{padding-right:10px;}
	
	.paymentList>div>div .date{display:flex; align-items:center;}
	.paymentList>div>div .date svg{display:block; width:13px; height:13px; margin-right:10px;}