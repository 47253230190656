/**********************************************ДОП ОПЦИИ*****************************************************************************/
.additional_service {}
.additional_service .title h3{font-weight:600; font-size:16px; margin-bottom:10px;}
.additional_service .title p{font-size:13px; color:var(--txt-color-gray); margin-top:10px;}
.additional_service .title .icon { margin-left:10px;}
.additional_service .title .icon svg{width:20px; height:20px;}
.additional_service .title .discount{padding-top: 3px;}
.additional_service .title .discount span{border-radius: 10px; padding: 2px 5px;
	 background-color: var(--background_blue);
	color: white;}

.additional_service .option{padding-bottom:20px; border-bottom:1px solid rgba(0,0,0,0.2);}
.additional_service .option .service_option{display:flex; padding:10px 0 10px 0px; justify-content:space-between;}
.additional_service .option .service_option>div{position:relative;}
.additional_service .option .service_option>div:nth-child(1){width:70%}
.additional_service .option .service_option>div:nth-child(2){width:20%}
.additional_service .option .service_option>div:nth-child(3){width:10%}

.additional_service .option  .calc{opacity:0; transition:opacity 0.2s ease-in;}
.additional_service .option  .calc span{font-size:11px; color:var(--txt-color-gray);}
.additional_service .option  .service_option .calc span{display:none;}
.additional_service .option  .service_option .experemental span{display:block;}
.additional_service .option  .service_option .per_hour span{display:block;}

.additional_service .option .name{font-size:0.9em;}
.additional_service .option .price{font-size:13px; color:var(--txt-color-gray);}
.additional_service .option .price>b{color:#63B900;}

.additional_service .option .checkbox{width:100%; position:relative;}
.additional_service .option .checkbox:hover{cursor:pointer;}
	.additional_service .checkbox>div{position:absolute; width:18px; height:18px; border:3px; top:0; right:0;
		border:2px solid rgba(0, 0, 0, 0.54); border-radius: 3px;
		background-color:white; transition:background-color 0.2s ease-in;}
	
	.additional_service  .active .checkbox>div{
		position:absolute; width:18px; height:18px; border:3px;
		border:2px solid #63B900; border-radius: 3px;
		background-color:#63B900;}
	
	.additional_service .option .checkbox svg{
		display:block; position:absolute; width:15px; height:15px; top:50%; left:50%;
		transform:translate(-50%,-50%); 
		fill:white; opacity:0;	transition:fill 0.2s ease-in;}
		
	.additional_service .active .checkbox svg{fill:white; opacity:1}
	.additional_service .active  .calc{opacity:1;}
	
	/*
	.experimental .price{display:none;}
	.experimental .calc {display:none;}
	*/
	.additional_service>.option>p{display:none;}
	.experimental>.option>p{display:block; margin:0; font-size:10px; color:var(--txt-color-gray);}
	.additional_service:last-child{margin-bottom:100px;}