



/************************************************************НЕ ТАБЛИЧНЫЙ ВИД***********************************************/
.time_container{display:block; position:relative; width:100%; overflow-x:scroll;}
 .time_table {
	display:flex; position:relative; width:max-content; justify-content: center;
	padding: 0 20px 100px 20px; margin:0 auto; box-sizing:border-box;
}
 .time_table>.scale{width:60px;}
 .time_table>.row{display: block; position:relative; width:150px;}
	
		 .time_table>.row ul{padding:0; margin:0; list-style-type: none;}
		 .time_table>.row ul>li{display:block; position:relative; width:100%; height:70px; 
			border-top:1px solid rgba(0,0,0,0.1); border-left:1px solid rgba(0,0,0,0.1); box-sizing:border-box;
		 }
		 .time_table>.row:nth-child(2) .rowName{border-left:1px solid rgba(0,0,0,0.1); }
		 .time_table>.row ul>li:last-child{border-bottom:1px solid rgba(0,0,0,0.1);}
		 .time_table>.row:last-child ul>li{border-right:1px solid rgba(0,0,0,0.1);}
		 .time_table>.row ul li>p{display:flex; justify-content:center; align-items:center; margin:0; padding:0;
									position:absolute; 	top:0; left:0; width:100%; height:100%;}
		.time_table>.row ul li>div{transition: background-color 0.1s ease-in}
		 .time_table>.row ul li .interval:nth-child(2){position: absolute; top:0; height:50%; width:100%;}
		 .time_table>.row ul li .interval:nth-child(3){position: absolute; top:50%; height:50%; width:100%;}
		 .time_table>.row .errorChose .inactive{background-color: rgba(255,0,0,0.3);}
		 
		.time_table>.row ul li  .interval>span{display:none; position:absolute; left:-20px; top:-1px; height:1px; width:20px;
												 background:rgba(0,0,0,0.1) } 
	    .time_table>.row ul li  .interval>span>i{display:block; position:absolute; right:25px; top:0; text-align:right; 
													transform: translateY(-50%);
													font-style:normal; font-size:14px; font-weight:600;}
		.time_table>.row ul li  .interval>.half{left:-10px; width:10px;}
		.time_table>.row ul li  .interval>.half>i{font-size:12px; font-weight:300; color:var(--txt-color-gray); }
		.time_table>.row:nth-child(2) ul li  .interval>span{display:block;}
		.time_table>.row ul li  .interval>i{display:block; position: absolute; top:50%; left:10px; transform:translateY(-50%); font-size:10px;}
		.time_table>.row .rowName{position: relative; height:50px; width:100%;
								display:flex; justify-content:center; align-items:center;}
								
		.time_table>.row ul li  .interval>div{display:block; position:absolute; top:0; bottom:0; width:4px; background-color:var(--background-color-gr)}
		.time_table>.row ul li  .checked{background-color:rgba(88, 157, 0, 0.54);}
		.time_table>.row ul li  .checked>div{display:none;}
		.time_table>.row ul li  .inactive>div{display:none;}
		.time_table>.row ul>.inactive>p{display:none;}
		
		.mapTag{display:block; color:red; background-color:white; width:100px; height:50px; overflow: hidden;}