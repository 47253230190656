.popup {position:absolute; padding:5px; }

.map_area{display:none;}
.map{position:absolute; top:0; left:0; width:100%; height:100%;}
 .popup-bubble {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    min-width: 50px;
    transform: translate(-50%, -50%);
    /* Style the bubble. */
    background-color: white;
    padding: 5px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		color: #63B900;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 14px;
		/* identical to box height */

		text-align: center;
		letter-spacing: -0.2px;
  }
      /* The parent of the bubble. A zero-height div at the top of the tip. */
  .popup_checked{
	  background: var(--linear-gradient-gr);
	  color:white;
	  z-index:20;
  }
  .popup-bubble:hover{cursor: pointer;}
  .popup-bubble-anchor {
        position: absolute;
        width: 100%;
        bottom: 8px;
        left: 0;
		cursor:pointer;
      }
	  /*
      .popup-bubble-anchor::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid white;
      }
	  */
      .popup-container {
        cursor: auto;
        height: 0;
        position: absolute;
        width: 200px;
      }


.map_clubs{position:absolute; left:0; bottom:0; width:100%; background-color:white; overflow:hidden;
	background: #FFFFFF;
	box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.12), 0px 0px 12px rgba(0, 0, 0, 0.24);
	border-radius: 24px 24px 0px 0px;
	transform:translateY(100%);
	transition:transform 0.2s ease-in;
}

.map_clubs>.map_clubs_container{position:relative; margin-top:0; width:100%; overflow:hidden; overflow-x:scroll; scroll-behavior: smooth; }
.map_clubs>.map_clubs_container>.map_clubs_list_frame{ width:180%; display:flex;}
.map_clubs>.map_clubs_container>.map_clubs_list_frame>.club{position:relative; top:0; width:80%;}