
.react-calendar{
    border: none!important;
   
}
.react-calendar__month-view>div>div>div{
    border-radius: 10px!important;
    overflow: hidden!important;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
    border-radius: 50px!important;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
    border-radius: 50px;
}
.react-calendar__tile--now {
    border-radius: 50px!important;
}
.react-calendar__tile--active {
    border-radius: 50px!important;
}