.input_fields{display:block; position:relative; background-color:white; border-radius:10px;}

.simple_input{display:block; position:relative; align-items:baseline; padding:15px 0 0 0; }
	.simple_input>div{padding:5px 0 5px 0; }
	.simple_input>div:nth-child(1){width:100%; text-align:left; padding-right:10px; box-sizing:border-box; color:rgba(0, 0, 0, 0.54);}
	.simple_input>div:nth-child(2){width:100%; position:relative; border-bottom:1px solid rgba(0,0,0,0.1); }
	.simple_input>div>input{display:block; position:relative; width:100%; border:none; 
				padding:0; margin:0; border:0;
				 font-size:16px; font-weight: 400; text-align:left;}
	.simple_input>div>input:focus{outline: none;}
	
	.simple_input svg{display:block; width:20px; height:20px; fill:rgba(0,0,0,0.1); position:absolute; right:0; top:50%; transform:translateY(-50%); }
	
	.simple_checkbox>div{padding:0; margin:5px 0 5px 0;}
	.simple_checkbox>div:nth-child(2){border-bottom:none; }
	.simple_checkbox svg{ width:18px; height:18px;}
	
	.simple_select>div>select{display:block; position:relative; width:100%; border:none; 
				padding:0; margin:0; border:0;
				 font-size:20px; text-align:left;}
	.simple_select>div>select:focus{outline: none;}
	
	.double_input>div:nth-child(2){border-bottom:none; display:flex; justify-content:space-between;}
	
	.double_input>div:nth-child(2)>div{display:flex;}
	.double_input>div:nth-child(2)>div>div:nth-child(1){padding-right:10px; color:rgba(0, 0, 0, 0.54)}
	
	.double_input>div:nth-child(2)>div>div input{display:block; border:none;  border:0;
				 font-size:15px; text-align:left;}
	.double_input>div:nth-child(2)>div>div input:focus{outline: none;}
	.double_input>div:nth-child(2)>div>div:nth-child(2){ border-bottom:1px solid rgba(0,0,0,0.1); position:relative;}
	.double_input svg{width:15px; height:15px;}
	
.foto_input{display:flex;  flex-wrap:wrap; position:relative; justify-content:space-between; padding:10px 0 0 0;}
	.foto_input>div{position:relative; width:calc(50% - 5px); padding-top:15px;}
		.foto_input>div>div{position:relative; overflow:hidden; padding-bottom:55%; border-radius:10px; background-color:rgba(0,0,0,0.1)}
			.foto_input>div>div>img{position:absolute; top:0; left:0; width:100%;}
			.foto_input>div>div>.foto_overlay{position:absolute; top:0; left:0;right:0; bottom:0; background-color:rgba(0,0,0,0.5); opacity:0;
											transition:opacity 0.2s ease-in;
			}
			.foto_input>div>div>.foto_overlay>div{position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:auto; }
			.foto_input>div>div>.foto_overlay>div>div{display:flex; justify-content:left; border:1px solid rgba(100,200,10,1); margin-bottom:10px;
													padding:10px; box-sizing:border-box; border-radius:10px; width:auto; white-space:nowrap;
													color:rgba(100,200,10,1);
			}
			.foto_input>div>div>.foto_overlay>div>div svg{fill:rgba(100,200,10,1);}
			.foto_input>div>div>.foto_overlay:hover{opacity:1;}
			.foto_input>div>div>.foto_overlay>div>div:hover{cursor:pointer; color:white; background:var(--linear-gradient-gr); border:1px solid rgba(0,0,0,0)}
				.foto_input>div>div>.foto_overlay>div>div:hover svg{fill:white;}
			.foto_input>div>div>.foto_overlay>div svg{width:20px; height:20px; margin-right:10px;}
			.foto_input>.new_photo:hover{cursor:pointer; }
				.foto_input>.new_photo:hover div{background-color:black; color:white;}
				.foto_input>.new_photo:hover svg{fill:white;}
			.foto_input>.new_photo>div>div{position:absolute; display:flex; 
								flex-direction: column; align-items: center;
								top:50%; left:50%; transform:translate(-50%,-50%);}
			.foto_input>.new_photo svg{display:block; width:30px; height:30px;}
			
.simple_textArea{display:block; position:relative; padding:15px 0 0 0;}
	.simple_textArea>div:nth-child(1){width:100%; text-align:left; padding-right:10px; margin-bottom:20px; box-sizing:border-box; color:rgba(0, 0, 0, 0.54);}
	.simple_textArea>div:nth-child(2){width:100%; position:relative; padding-bottom:25%; height:0;}
	.simple_textArea>div textarea{display:block; position:absolute; width:100%; height:100%;
								border:1px solid rgba(0,0,0,0.1); border-radius:10px;
	}
	.simple_textArea>div textarea:focus{outline: none;}
	
	

.controls{display:flex; align-items:center; justify-content:space-between;}

	.time_selected{display:flex; align-items:center; border-radius:30px; overflow:hidden; border:1px solid rgba(0,0,0,0.1);}
		.time_selected>div{width:50%; padding:10px 20px; }
		.time_selected>.checked{background: var(--linear-gradient-gr)}
		.time_selected>div:hover{cursor:pointer;}
		
		
.additional_service b>input{margin-right:10px; border:none; width:100px; text-align:right;}
	.additional_service b>input:focus{outline: none;}
	
.save_data{display:flex; justify-content:center; margin-top:20px;}

	.save_data>.save_bottom{display:flex; position:relative; align-items:center; padding:10px 15px;
					border-radius:30px; background:white; box-sizing:border-box;
					color:inherit; transition:background 0.2s ease-in;}
	.save_data>.save_bottom svg{display:block;  width:20px; height:20px; margin-right:10px; }	
	.save_data>.save_bottom:hover{cursor:pointer; background:var(--linear-gradient-gr); background-size: 400% 400%;
							color:white; animation:gradient 3s ease infinite;
							box-shadow:var(--box-shadow-grn);}
	.save_data>.save_bottom:hover svg{fill:white;}
	.save_data input{display:block; position:absolute; top:0; left:0; width:100%; height:100%; opacity:0; }
	.save_data input:hover{cursor:pointer;}
	
	
	.checkbox>div {
		position: relative;
		width: 18px;
		height: 18px;
		border: 3px;
		border: 2px solid rgba(0, 0, 0, 0.54);
		border-radius: 3px;
		background-color: white;
		transition: background-color 0.2s ease-in;
	}
	.checkbox{width:20px; height:20px;}
	.checkbox svg{fill:white;}
	.checkbox_active svg{fill:white;}
	.checkbox_active>div{
		position: relative;
		width: 18px;
		height: 18px;
		border: 3px;
		border: 2px solid #63B900;
		border-radius: 3px;
		background-color: #63B900;
	}
	
	.clientInfoPage{position:relative; display:flex; justify-content:space-between; align-items:center;}
		.clientBigPhoto{width:29%; position:relative;}
			.clientBigPhoto>div{position:relative; width:100%; height:0; padding-bottom:100%; border-radius:50%;
						overflow:hidden; background-color:gray;}
			.clientBigPhoto>div>img{position:absolute; top:0; left:0; width:100%;}
	
	.clientAddInfo{width:69%; position:relative;}
	
		.clientAddInfo>div{display:flex; align-items:center; position:relative; margin-bottom:20px;}
			.clientAddInfo>div>div{position:relative; box-sizing:border-box;}
			.clientAddInfo>div>div>svg{display:block; width:20px; height:20px; fill:rgba(0,0,0,0.5);}
			.clientAddInfo>div>div:nth-child(1){width:20px; margin-right:10px;}
			.clientAddInfo>div>div:nth-child(2){width:20%; margin-right:10px; color:rgba(0,0,0,0.5);}
			.clientAddInfo>div>div:nth-child(3){width:50%;}

/*RADIO BUTTOM*/
.radiogroup{display:flex; justify-content:space-between; align-items:center; }
	.radiogroup .wrapper{display:flex; align-items:center;}
	.radiogroup input{width:30px; height:30px;}
	.radiogroup label{padding-left: 10px;}
.document_Iframe{position:fixed; top:0; left:0; width:100%; height:100%; z-index:-2; transition:opacity 0.2s ease-in;}
	.document_Iframe>.overlay{position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.6)}
	.document_Iframe>.iframe{position:absolute; top:20px; bottom:20px; left:20px; width:calc(100% - 40px); background-color:white;}
	.document_Iframe>.iframe iframe{display:block; position:absolute; top:0; left:0; width:100%; height:100%;}
	.document_Iframe>.iframe>.close_iframe{
				display:flex; align-items:center; justify-content:center;
				position:absolute; right:10px; top:10px; width:43px; height:43px;
				border-radius:50%;background-color:rgba(0,0,0,0.9);
	}
	.document_Iframe>.iframe>.close_iframe>svg{display:block; width:25px; height:25px; fill:white;}
	.document_Iframe>.iframe>.close_iframe:hover{cursor:pointer;}
	

@media (min-width: 1024px) {
	.document_Iframe>.iframe{left:50%; width:70%; transform:translateX(-50%)}
}