.bottom_menu{
	position:fixed; bottom:0; left:0; right:0;
	padding:10px 5px; box-sizing:border-box;
	background:var(--linear-gradient-gr);
	box-shadow:var(--box-shadow-grn);
	border-top-left-radius:20px;
	border-top-right-radius:20px;
	z-index:2;
	transition: transform ease-in 0.2s;
}
.bottom_menu>nav{position:relative;}
.bottom_menu>nav>ul{padding:0; margin:0;  list-style-type: none;}
.bottom_menu>nav>ul{
	display:flex; justify-content:space-between;
}

.bottom_menu>nav li{width:25%; transition:color 0.2s ease-in;}
.bottom_menu>nav li a{color: inherit; text-decoration: none;}
.bottom_menu nav .text{text-align:center; font-size:12px;}
.bottom_menu nav .icon{display:flex; justify-content:center;}
.bottom_menu nav svg{display:block; width:20px; height:20px; fill:var(--icon-color); transition:fill 0.2s ease-in;}
	.bottom_menu nav li:hover{cursor:pointer;}
	.bottom_menu nav li a:active{color:white;}
	
	.bottom_menu nav li a:active svg{fill:white;}
	.bottom_menu nav li .active{color:white;}
	.bottom_menu nav li .active svg{fill:white;}
	
	.headerOpen{transform:translateY(0)}
	.headerClosed{transform:translateY(120%)}
body>header>a{display:none;}
.headerHidden{display:none;}

@media (min-width: 1024px) {
	.headerHidden{display:block;}
	.headerClosed{transform:translateY(0)}
	
	header{position:fixed; top:0; left:0; bottom:0; width:250px; }
	header>a{display:block; position:absolute; top:10px; left:10px; right:10px; height: 200px;}
	header>a>svg{display:block; position:absolute; top:0px; left:0px; right:0px; bottom:0; fill:white; }
	
	.bottom_menu{border-top-left-radius:0px; border-top-right-radius:0px;}
	.bottom_menu>nav{position:absolute; top:220px; left:10px; right:10px;}
	
	.bottom_menu>nav>ul{display:block; position:relative; }
	.bottom_menu>nav>ul>li {position: relative; width: 80%;}
	.bottom_menu>nav>ul>li a{display:flex; align-items:center; width:auto; padding-bottom:20px;  width: 90%;}
	.bottom_menu nav .text{font-size:16px;}
	.bottom_menu>nav>ul>li .icon{padding-right:20px;}
}