.msg{position:absolute; top:0; left:0; width:100%; height:100%; z-index:-1; transition:opacity 0.2s ease-in;}
.msg .overlay{position:absolute; top:0; left:0; width:100%; height:100%; background-color:rgba(0,0,0,0.4)}
.msg .confirmMsg{position:absolute; top:50%; left:50%; width:60%; transform:translate(-50%, -50%); background-color:white;
					padding:20px; border-radius:20px; text-align:center; color:rgba(0,0,0,0.6) }
					
.msg .choose{position:relative; display:flex; padding-top:20px; justify-content:space-around; align-items: center;}
.msg .choose>div{width:30%;}

.msg .confirmMsg .chooseRefund{position:relative; background-color:white;}
.msg .confirmMsg .respondWaiting{position:absolute; top:0; left:0; width:100%; height:100%; display:none;}
.msg .confirmMsg .respondWaiting>img{position:absolute; top:50%; left:50%; transform:translate(-50%, -50%);}
.msg .choosenWaitnig .chooseRefund{visibility:hidden}
.msg .choosenWaitnig .respondWaiting{display:block;}