.phone_enter{position: absolute; top:50%; left: 0; width: 100%; transform: translateY(-50%);}
.profile_info{height:100%!important; overflow-y: scroll;}
.profile_info .name h1{margin: 10px 0;}
.profile_info .name p{font-size: 14px;}
.profileMsg li{list-style-type: none;}
.profile_info{background: linear-gradient(93.1deg, rgba(175, 219, 0, 0.85) 0%, rgba(102, 183, 0, 0.85) 100%);
				box-shadow: 0px -3px 15px rgba(149, 192, 22, 0.5);
				backdrop-filter: blur(16px);}
.profile_info .name{position:relative;  top:0; left:0; width:100%; height:100px; color:white; 
					display:flex; align-items:center; justify-content:center;
					text-align:center;font-weight: 500; font-size: 26px; line-height: 108.9%;
					box-sizing:border-box;}		
.profile_info .userInfo{position:relative; margin-top: 50px; width:100%; padding:50px 20px;  background: #FFFFFF;
						box-shadow: 0px -3px 15px rgba(149, 192, 22, 0.5);
						backdrop-filter: blur(16px);
						border-radius: 26px 26px 0px 0px; box-sizing:border-box;}
				
		
.profile_info .person_photo{position:absolute; top:0; left:50%; width:100px; height:100px; transform:translate(-50%, -50%);}
	.person_photo>div{position:absolute; width:100%; border-radius:50%;  padding-bottom:100%; height:0; overflow:hidden;
		 background:white;}
		.person_photo>div>div{position:absolute; top:1%; left:1%; width:98%; height:98%; 
						background:linear-gradient(93.1deg, rgba(175, 219, 0, 0.85) 0%, rgba(102, 183, 0, 0.85) 100%);
						border-radius:50%;}
		.person_photo>div>img{position:absolute; top:0; left:0; width:100%;}
		.profile_info .person_photo svg{display:block; position:absolute; top:-1%; left:-1%;  width:102%; height:102%; fill:white;}
.profile_info >.userInfo>a{display: block; color: inherit; text-decoration: none;}
.profile_info >.userInfo .tags{display:flex; align-items:center; justify-content:space-between; margin:30px 0 30px 0; font-weight: 600; font-size: 16px; line-height: 24px;}
	.profile_info >.userInfo .tags>div:nth-child(1){display:flex; align-items:center;}
	.profile_info >.userInfo .tags:hover{cursor:pointer;}
	.profile_info  .tags svg{display:block; width:30px; height:30px; margin-right:30px; fill:rgba(0,0,0,0.54)}
	.profile_info >.userInfo .tags .profile_discount{
			display:block;
			padding: 0 20px; border-radius:20px;
			background-color: var(--background_blue); color:white;
			transition:opacity 0.2s easy-in}
.profileDiscount{transform: translateX(100%);}
.profileMsg{transform: translateX(100%);}
.profilePayments{transform: translateX(100%);}
.profileHelp{transform: translateX(100%);}
.profileUserInfo{transform: translateX(100%);}
.profileAgreementInfo{transform: translateX(100%);}

.profileInfo{transition:opacity 0.2s ease-in; position:relative;}
.profileInfo>form .btn_g>input{background:none; color:white; font-size:16px; border:none;}
.profileInfo>form .btn_g>input:active{ border:none; background:none; }
.profileInfo>form .btn_g>input:focus{ border:none; background:none; }
.profileForm{background-color:white; }
.profile_download{display:block; position:absolute; z-index: -1; top: 0; left: 0;  bottom: 0;     right: 0;}

.viberBot>a{display:flex; align-items:center; color:inherit; text-decoration:none;}
.viberBot>a>span{display:block;}
.viberBot>a>span>svg{display:block; margin-right:15px; width:35px; height:35px;}

.profileAgreementInfo>section{transition:transform 0.2s ease-in;}
	.profileAgreementInfo>.info{transform:translate(0%);}
	.profileAgreementInfo>.document{position:fixed; top:0; left:0; width:100%; height:calc(100%); transform:translate(100%);}
	
	.profileAgreementInfo>section .header_tt{
		display:flex; justify-content:space-between;
		box-shadow:var(--box-shadow-down);
	}
	.profileAgreementInfo>section .header_tt svg{display:block; width:30px; height:30px;}
	.profileAgreementInfo .iframe{display:block; position:absolute; top:70px; left:0; width:100%; bottom:0px;}
	.profileAgreementInfo .iframe iframe{position:absolute; top:0; left:0; width:100%; height:100%;}
	
 .discounts{padding: 20px 0;} 
 .discounts .current{position:relative;  display:flex; align-items:center; justify-content:left;}
 .discounts .current>div{
	 padding: 0px 50px 0px 30px;
	 border-top:2px solid rgba(0,0,0,0.1);  border-bottom:2px solid rgba(0,0,0,0.1); 
	 border-right:2px solid rgba(0,0,0,0.1); 
	 border-radius: 0% 70px 70px 0%}
 .discounts .current>div>span{font-size: 30px; color:var(--color_txt_blue); font-weight:bold;}
 .discounts .current>div>span>b{font-size: 100px; font-weight:bold;}
 
 .discounts ul{padding:10px;}
 .discounts ul li{display:block; position:relative; overflow:hidden; margin-bottom:20px;}
 .discounts ul li .decor{
			position:absolute; top:50%; transform:translateY(-50%); height:20px; width:20px;
			border-radius:50%; background-color:white;}
 .discounts ul li .left{left:0px; transform:translate(-50%, -50%)}
 .discounts ul li .right{right:0px; transform:translate(50%, -50%); border:2px solid rgba(0,0,0,0.1)}
 .discounts ul li>.discount_info{
		position:relative; 
		display: flex; align-items:center; justify-content:space-between;
		border:2px solid rgba(0,0,0,0.1);
	}
 .discounts ul li>.discount_info>div{width:50%;}
 .discounts ul li>.discount_info>.value{position:static;}
 .discounts ul li>.discount_info>.value>div{
	 display:flex; align-items:center; justify-content:center;
	 position:absolute; top:50%; left:-2px; transform:translateY(-50%);
	 min-height:70%;
	 background-color:var(--background_blue); color: white; font-size: 30px;
	 border-radius: 0% 35px 35px 0%}
  
 .discounts ul li>.discount_info>.value>div>span{padding:5px 25px;}
 
 .discounts ul li>.discount_info>.condition{padding:10px 10px 10px 30px; color: rgba(0, 0, 0, 0.54); font-size:13px;}
 .discounts ul li>.discount_info>.condition b{color:var(--main-txt-color); font-size:16px;}
 .discounts ul li>.discount_info>.condition b span{font-size:25px;}
 .discounts ul li>.discount_info>.condition>div:nth-child(1){margin-bottom: 10px;}
 .discounts ul li>.discount_info>div .date{display:flex; align-items:center; color:var(--main-txt-color); margin-top:10px;}
 .discounts ul li>.discount_info>div .date>div:nth-child(1){margin-right:5px;}
 .discounts ul li>.discount_info svg{display:block; width:10px; height:10px;}
 
 .non_discount .icon{position:relative; display:flex; align-items:center; justify-content:center; padding:20px;} 
 .non_discount .icon svg{display:block;}
 .non_discount .icon>div{
		position:absolute; top:50%; left:50%; transform:translate(-50%, -50%);
		font-size:50px; color:rgba(0,0,0,0.1);}
 .non_discount .text{padding:0 20px;}

 .personInfo{
	position: absolute; top:0; left: 0; width: 100%; bottom: 55px;
	overflow: hidden; overflow-y: scroll;
}
 
@media (min-width: 1024px) {
.profileAgreementInfo .iframe{width: 100%!important; bottom: 0;}
.registration .regInfo{position: relative; max-width:50%; margin:0 auto;} 
.profile_info .info{padding: 50px 25%; box-sizing:border-box;}
.profileMsg   .info>.activationMsg{padding: 0 25%; box-sizing:border-box;}
.profilePayments .info>.paymentList{padding: 0 25%; box-sizing:border-box;}
.profileDiscount .info>.discounts{padding: 20px 25%; box-sizing:border-box;}
.profileDiscount .info>.non_discount{padding: 20px 25%; box-sizing:border-box;}
.discounts .current{position:relative;  display:flex; align-items:center; justify-content:center;}
.discounts .current>div{
	 padding: 0px 50px;
	 border:2px solid rgba(0,0,0,0.1);  
	 border-radius: 70px}
.discount .info>.non_discount{padding: 0 25%; box-sizing:border-box;}
.profileHelp 	 .info>div:nth-child(2){padding: 30px 25%; box-sizing:border-box;}
.profileUserInfo .info>.profileInfo{padding: 30px 25%; box-sizing:border-box;}
.profileAgreementInfo .info>div:nth-child(n+2){padding: 20px 25%; box-sizing:border-box;}
.profile_info >.userInfo>a{display: block; max-width: 50%; margin: 0 auto;}
.profile_info >.userInfo>.tags{display: block; max-width: 50%; margin: 0 auto;}
.personInfo{bottom: 0;}
.personInfo>div:nth-child(2){width: 50%; margin: 0 auto;}
}